import BgTooltip from './custom-tooltips/bgTooltip.vue';
import Vue from 'vue';
import { VendorOptions, RowData, FilterChangedEvent, TableTranslationObject } from './contracts/table-data';
import { AgGridVue } from 'ag-grid-vue';
import { AG_GRID_LOCALE_EN } from '@/locales/table/locale.en';
import { AG_GRID_LOCALE_DE } from '@/locales/table/locale.de';
import { NewColumnsLoadedEvent, SelectionChangedEvent } from 'ag-grid-community';

const CustomAgGridTable = Vue.extend({
    name: 'CustomAgGridTable',
    components: {
        AgGridVue,
        BgTooltip
    },
    props: {
        vendorOptions: {
            type: Object as () => VendorOptions<any>,
            default: {},
            required: true,
        },
        tableName: {
            type: String,
            required: true,
        },
        overlayLoadingTemplate: {
            type: String,
            default: undefined
        },
        overlayNoRowsTemplate: {
            type: String,
            default: undefined
        }
    },
    data(): {
        gridApi: any;
        columnApi: any;
        minRowHeight: number;
        currentRowHeight: number;
        tooltipShowDelay: number;
        animateRows: boolean;
        suppressPaginationPanel: boolean;
        defaultColDef: {
            editable: boolean;
            sortable: boolean;
            resizable: boolean;
            filter: boolean;
            flex: number;
            minWidth: number;
            minHeight: number;
            unSortIcon: boolean;    //show sort icons even if no sort selected?
            tooltipComponent: any;
        };
        localeText: TableTranslationObject | null;
        availableTranslations: {
            [key: string]: TableTranslationObject;
            'en-GB': TableTranslationObject;
            'de-DE': TableTranslationObject;
        };
        currentPageSize: number;
        } {
        return {
            gridApi: null,
            columnApi: null,
            minRowHeight: 0,
            currentRowHeight: 0,
            tooltipShowDelay: 0,
            animateRows: true,
            suppressPaginationPanel: true,
            defaultColDef: {
                editable: false,
                sortable: true,
                resizable: true,
                filter: true,
                flex: 1,
                minWidth: 100,
                minHeight: 50,
                unSortIcon: true,
                tooltipComponent: BgTooltip
            },
            localeText: null,
            availableTranslations: {
                'en-GB': AG_GRID_LOCALE_EN,
                'de-DE': AG_GRID_LOCALE_DE,
            },
            currentPageSize: 0,
        }
    },
    created(): void {
        this.localeText = this.getTableTranslation() ?? this.availableTranslations['en-GB'];
        this.suppressPaginationPanel = (this.vendorOptions.suppressPaginationPanel == 'false') ? false : true;
    },
    methods: {
        onGridReady (params: any): void {
            this.gridApi = params.api;
            this.gridApi.showLoadingOverlay();
            this.columnApi = params.columnApi;
            this.minRowHeight = params.api.getSizesForCurrentTheme().rowHeight;
            this.currentRowHeight = this.minRowHeight;
        },
        onFirstDataRendered(params: any): void {
            this.onGridSizeChanged(params);
        },
        getSelectedRows(): Array<RowData>{
            const selectedNodes = this.gridApi.getSelectedNodes();
            return selectedNodes.map((node: any) => node.data);
        },
        onRowSelected(event: any): void {
            this.$emit('eventSelected', event);
        },
        onPaginationChange(newPage: any): void {
            this.$emit('paginationChanged', newPage);
            if (this.gridApi && this.gridApi.paginationGetPageSize() != this.currentPageSize) {
                this.currentPageSize = this.gridApi.paginationGetPageSize();
                this.$emit('pageSizeFound', this.gridApi.paginationGetPageSize());
            }
        },
        onCellDoubleClicked(cellDoubleClickedEvent: any): void {
            this.autoSizeColumns(true, cellDoubleClickedEvent.column.colId);
        },
        onGridSizeChanged(params: any): void {

            const bodyViewportList = document.querySelectorAll(`div[eventTableName=${this.tableName}] .ag-body-viewport`);

            if (!bodyViewportList || bodyViewportList.length === 0) {
                return;
            }

            if (bodyViewportList.length > 1) {
                console.warn(`More than one viewport found for the event table.
                              This can lead to a wrong rendering behaviour.
                              Choose a unique name for the table to fix this issue.
                              The currently used name is ${this.tableName}`);
            }

            const bodyViewport = bodyViewportList[0];

            const gridHeight = bodyViewport.clientHeight;
            const renderedRowCount = params.api.getDisplayedRowCount();

            if (renderedRowCount * this.minRowHeight >= gridHeight) {
                if (this.currentRowHeight !== this.minRowHeight) {
                    this.currentRowHeight = this.minRowHeight;
                }
            } else {
                this.currentRowHeight = Math.floor(gridHeight / renderedRowCount);
            }
        },
        onSortChanged(sortChangedEvent: any): void {
            this.$emit('sortChanged', sortChangedEvent);
        },
        onFilterChanged (filterChangedEvent: FilterChangedEvent): void {
            this.$emit('filterChanged', filterChangedEvent);
        },
        autoSizeColumns(skipHeaders: boolean, ...columnIds: string[]): void {
            this.columnApi.autoSizeColumns(columnIds, skipHeaders);
        },
        autoSizeAllColumns(skipHeaders: boolean): void {
            if (this.columnApi) {
                const allColumnIds = [] as any[];
                this.columnApi.getAllColumns().forEach((column: any) => {
                    allColumnIds.push(column.colId);
                });

                this.columnApi.autoSizeColumns(allColumnIds, skipHeaders);
            }
        },
        getTableTranslation(): TableTranslationObject {
            return this.availableTranslations[this.$i18n.locale];
        },
        onSelectionChanged(event: SelectionChangedEvent): void {
            this.$emit('selectionChanged', event);
        },
        sizeColumnsToFit(): void {
            if (this.gridApi) {
                this.gridApi.sizeColumnsToFit();
            }
        },
        onColumnsLoaded(event: NewColumnsLoadedEvent): void {
            this.$emit('newColumnsLoaded', event);
        }
    }
})

export default CustomAgGridTable;
