
import { QuestionCatalogListEndpointRequest } from '@/models';
import { CatalogTypePermissionDto, QuestionCatalogDto, SubQuestionType } from '@/service-proxies/service-proxies.g';
import { QUESTIONNAIRE_DEFAULT_FILTER, filterChangedHandler } from '@/utils/table-utils';
import { SelectionChangedEvent } from 'ag-grid-community';
import _ from 'lodash';
import Vue from 'vue';
import XLSX from 'xlsx-js-style';
import ReadonlyQuestionnaireTable from '@/components/average-report/partials/readonly-questionnaire-table.vue';
import moment from 'moment';

const QuestionnaireExport = Vue.extend({
    name: 'QuestionnaireExport',
    components: {
        ReadonlyQuestionnaireTable
    },
    data (): {
        questionnaireFilterValues: QuestionCatalogListEndpointRequest;
        selectedQuestionCatalogs: QuestionCatalogDto[] | undefined;
        vendorOptions: any;
        defaultCellStyling: any;
        headerRowStyling: any;
        questionGroupRowStyling: any;
        globalNumberFormat: {
            numFmt: string;
        };
        } {
        const defaultCellStyling = {
            border: {
                top: {
                    style: 'medium',
                    color: { rgb: '000000' }
                },
                right: {
                    style: 'medium',
                    color: { rgb: '000000' }
                },
                bottom: {
                    style: 'medium',
                    color: { rgb: '000000' }
                },
                left: {
                    style: 'medium',
                    color: { rgb: '000000' }
                }
            },
            alignment: {
                vertical: 'center',
                horizontal: 'center',
                wrapText: true
            }
        };
        return {
            questionnaireFilterValues: {
                ..._.cloneDeep(QUESTIONNAIRE_DEFAULT_FILTER),
                catalogTypeIds: (this.$store.getters['userAccessManagement/getUserCatalogTypes'] as CatalogTypePermissionDto[])
                    .map(x => x.catalogTypeId).filter(x => x) as number[]
            },
            selectedQuestionCatalogs: undefined,
            vendorOptions: {
                rowSelection: 'multiple',
                data:
                    _.range(500).map(() => ({
                        'meta': {
                            questionCatalogDto: new QuestionCatalogDto(),
                        },
                        'col-description': '',
                        'col-year': undefined,
                        'col-language': '' ,
                        'col-type': '',
                        'col-version': undefined,
                        'col-testApproval': '',
                        'col-approval':  '',
                        'col-approvalDate': '',
                        'col-deadlineHReport': '',
                        'col-deadlineZReport': '',
                        'col-details': '',
                    })),
            },
            defaultCellStyling: {
                ...defaultCellStyling,
            },
            headerRowStyling: {
                fill: { fgColor: { rgb: 'fde9d9' } },
                ...defaultCellStyling,
            },
            questionGroupRowStyling: {
                fill: { fgColor: { rgb: 'c0c0c0' } },
                ...defaultCellStyling,
            },
            globalNumberFormat: {
                numFmt: '0'
            },
        }
    },
    computed: {
        exportButtonDisabled(): boolean {
            return !this.selectedQuestionCatalogs || !this.selectedQuestionCatalogs.length;
        }
    },
    methods: {
        questionnaireFilterChangedHandler (filters: QuestionCatalogListEndpointRequest): void {
            filterChangedHandler(filters, QUESTIONNAIRE_DEFAULT_FILTER, this.questionnaireFilterValues);
        },
        onQuestionnaireSelectionChange (event: SelectionChangedEvent): void {
            const nodes = event.api.getSelectedNodes();
            this.selectedQuestionCatalogs = nodes.map(n => n.data.meta.questionCatalogDto);
        },
        triggerGenerationOfExport (): void {
            let errorOccured = false;
            try {
                this.generateExcelExport();
            } catch (e) {
                console.error(`An error occurred while generating the excel export:\n ${e}`);
                (this as any).$pui.toast({
                    type: 'error',
                    title: this.$t('export.error.errorTitle').toString(),
                    copy: this.$t('export.error.errorText').toString(),
                });
                errorOccured = true;
            }

            if (!errorOccured) {
                (this as any).$pui.toast({
                    type: 'success',
                    title: this.$t('export.success.successTitle').toString(),
                    copy: this.$t('export.success.successText').toString(),
                });
            }
        },
        //generateExcelExport(): void {
        //    const workbook = XLSX.utils.book_new();

        //    this.selectedQuestionCatalogs?.forEach(q => {
        //        const flattenedData = this.flattenQuestionCatalogData(q);
        //        const worksheet = XLSX.utils.aoa_to_sheet(flattenedData);

        //        worksheet['!cols'] = [
        //            { wpx: 30 },
        //            { wpx: 30 },
        //            { wpx: 30 },
        //            { wpx: 560 },
        //            { wpx: 75 },
        //            { wpx: 50 },
        //            { wpx: 50 },
        //            { wpx: 50 },
        //            { wpx: 140 },
        //        ];

        //        worksheet['!rows'] = Array(flattenedData.length).fill({ hpx: 20 }, 0, 1).fill({ hpx: 60 }, 1, 2).fill({ hpx: 40 }, 2);

        //        // merge for the first/description row
        //        const merges = [
        //            { s: { r: 0, c: 0 }, e: { r: 0, c: 6 } },
        //            { s: { r: 0, c: 7 }, e: { r: 0, c: 8 } }
        //        ];

        //        worksheet['!merges'] = merges;

        //        // find unique name for worksheet
        //        let worksheetName = `${q.description?.substring(0, 10)}`;
        //        if (workbook.SheetNames.includes(worksheetName)) {
        //            const lastName = [...workbook.SheetNames].sort().pop();
        //            const currentNumber = Number(lastName?.split('-')?.pop());
        //            const counter = isNaN(currentNumber) ? 1 : currentNumber + 1;
        //            worksheetName = `${worksheetName}-${counter}`;
        //        }

        //        XLSX.utils.book_append_sheet(workbook, worksheet, worksheetName);
        //    });

        //    XLSX.writeFile(workbook, `${this.$t('export.questionnaireFileName')}-${moment(Date.now()).local().format('DD.MM.YYYY-HH:mm')}.xlsx`, { cellStyles: true });
        //},
        generateExcelExport(): void {
            const workbook = XLSX.utils.book_new();

            this.selectedQuestionCatalogs?.forEach(q => {
                const flattenedData = this.flattenQuestionCatalogData(q);
                const worksheet = XLSX.utils.aoa_to_sheet(flattenedData);

                this.setWorksheetColumns(worksheet);
                this.setWorksheetRows(worksheet, flattenedData.length);
                this.mergeWorksheetCells(worksheet);

                const worksheetName = this.getUniqueWorksheetName(workbook, q.description);
                XLSX.utils.book_append_sheet(workbook, worksheet, worksheetName);
            });

            const fileName = `${this.$t('export.questionnaireFileName')}-${moment(Date.now()).local().format('DD.MM.YYYY-HH:mm')}.xlsx`;
            const workbookBlob = this.workbookToBlob(workbook);

            this.openBlobInNewTab(workbookBlob, fileName);
        },

        setWorksheetColumns(worksheet: any): void {
            worksheet['!cols'] = [
                { wpx: 30 },
                { wpx: 30 },
                { wpx: 30 },
                { wpx: 560 },
                { wpx: 75 },
                { wpx: 50 },
                { wpx: 50 },
                { wpx: 50 },
                { wpx: 140 },
            ];
        },

        setWorksheetRows(worksheet: any, length: number): void {
            worksheet['!rows'] = Array(length).fill({ hpx: 20 }, 0, 1).fill({ hpx: 60 }, 1, 2).fill({ hpx: 40 }, 2);
        },

        mergeWorksheetCells(worksheet: any): void {
            worksheet['!merges'] = [
                { s: { r: 0, c: 0 }, e: { r: 0, c: 6 } },
                { s: { r: 0, c: 7 }, e: { r: 0, c: 8 } }
            ];
        },

        getUniqueWorksheetName(workbook: any, description: string | undefined): string {
            let worksheetName = `${description?.substring(0, 10)}`;
            if (workbook.SheetNames.includes(worksheetName)) {
                const lastName = [...workbook.SheetNames].sort().pop();
                const currentNumber = Number(lastName?.split('-')?.pop());
                const counter = isNaN(currentNumber) ? 1 : currentNumber + 1;
                worksheetName = `${worksheetName}-${counter}`;
            }
            return worksheetName;
        },

        workbookToBlob(workbook: any): Blob {
            const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
            return new Blob([wbout], { type: 'application/octet-stream' });
        },

        openBlobInNewTab(blob: Blob, fileName: string): void {
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            a.target = '_blank';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
        },
        flattenQuestionCatalogData(catalog: QuestionCatalogDto): Array<Array<{ v: any; t?: string; s?: any }>> {
            const questions = [];
            let totalWeight = 0;

            // Add a header description line to the top of the worksheet
            questions.push([
                { v: `${this.$t('pdfCreation.description')}: ${catalog.description} / ${this.$t('pdfCreation.catalogType')}: ${catalog.catalogType?.getDescription(this.$i18n.locale)}`, t: 's', s: { alignment: { vertical: 'center' }}},
                { v: '' },
                { v: '' },
                { v: '' },
                { v: '' },
                { v: '' },
                { v: '' },
                { v: `${this.$t('export.from')}: ${moment(Date.now()).local().format('DD.MM.YYYY HH:mm')}`, t: 's', s: { alignment: { vertical: 'center', horizontal: 'center' }}},
            ]);

            questions.push([
                { v: this.$t('pdfCreation.questionGroup'), t: 's', s: { ...this.headerRowStyling, alignment: { textRotation: 90, ...this.defaultCellStyling.alignment }, font: { sz: 7 } }},
                { v: this.$t('mainQuestionAnswerForm.title'), t: 's', s: { ...this.headerRowStyling, alignment: { textRotation: 90, ...this.defaultCellStyling.alignment }, font: { sz: 7 } }},
                { v: this.$t('pdfCreation.subQuestion'), t: 's', s: { ...this.headerRowStyling, alignment: { textRotation: 90, ...this.defaultCellStyling.alignment }, font: { sz: 7 } }},
                { v: '', t: 's', s: this.headerRowStyling },
                { v: this.$t('questionCatalogDetails.formLabels.mainQuestion.noRiskWhenAnsweredWithYes'), t: 's', s: this.headerRowStyling },
                { v: this.$t('export.questionType'), t: 's', s: this.headerRowStyling },
                { v: this.$t('questionCatalogDetails.formLabels.mainQuestion.weight'), t: 's', s: this.headerRowStyling },
                { v: this.$t('questionCatalogDetails.formLabels.mainQuestion.cycle'), t: 's', s: this.headerRowStyling },
                { v: this.$t('pdfCreation.reportComment.comment'), t: 's', s: this.headerRowStyling },
            ]);

            catalog.questionGroups?.sort((a, b) => a.position! - b.position!).forEach((group, groupIndex) => {
                totalWeight += group.sumWeighting || 0;

                // Create separator for each question group
                questions.push([
                    { v: groupIndex + 1, t: 'n', s: { ...this.questionGroupRowStyling, ...this.globalNumberFormat } },
                    { v: '', t: 'n', s: { ...this.questionGroupRowStyling, ...this.globalNumberFormat } },
                    { v: '', t: 'n', s: { ...this.questionGroupRowStyling, ...this.globalNumberFormat } },
                    { v: group.description, t: 's', s: this.questionGroupRowStyling },
                    { v: '', t: 's', s: this.questionGroupRowStyling },
                    { v: '', t: 's', s: this.questionGroupRowStyling },
                    { v: group.sumWeighting, t: 'n', s: { ...this.questionGroupRowStyling, ...this.globalNumberFormat } },
                    { v: '', t: 'n', s: { ...this.questionGroupRowStyling, ...this.globalNumberFormat } },
                    { v: '', t: 's', s: this.questionGroupRowStyling },
                ]);

                group.mainQuestions?.sort((a, b) => a.position! - b.position!).forEach((mainQuestion, mainQuestionIndex) => {
                    // We want to create an entry for a main question first and the immediately add its sub questions
                    questions.push([
                        { v: groupIndex + 1, t: 'n', s: { ...this.defaultCellStyling, ...this.globalNumberFormat } },
                        { v: mainQuestionIndex + 1, t: 'n', s: { ...this.defaultCellStyling, ...this.globalNumberFormat } },
                        { v: '', t: 'n', s: { ...this.defaultCellStyling, ...this.globalNumberFormat } },
                        { v: mainQuestion.text, t: 's', s: { ...this.defaultCellStyling, ...{ alignment: { vertical: 'center', horizontal: 'left' }} } },
                        { v: mainQuestion.noRiskWhenAnsweredWithYes ? this.$t('yes') : this.$t('no'), t: 's', s: this.defaultCellStyling },
                        { v: this.$t('export.mainQuestionAbbr'), t: 's', s: this.defaultCellStyling },
                        { v: mainQuestion.weight, t: 'n', s: { ...this.defaultCellStyling, ...this.globalNumberFormat } },
                        { v: mainQuestion.cycle, t: 'n', s: { ...this.defaultCellStyling, ...this.globalNumberFormat } },
                        { v: '', t: 's', s: this.defaultCellStyling },
                    ]);

                    mainQuestion.subQuestions?.sort((a, b) => a.position! - b.position!).forEach((subQuestion, subQuestionIndex) => {
                        questions.push([
                            { v: groupIndex + 1, t: 'n', s: { ...this.defaultCellStyling, ...this.globalNumberFormat } },
                            { v: mainQuestionIndex + 1, t: 'n', s: this.defaultCellStyling, ...this.globalNumberFormat },
                            { v: subQuestionIndex + 1, t: 'n', s: { ...this.defaultCellStyling, ...this.globalNumberFormat } },
                            { v: subQuestion.text, t: 's', s: { ...this.defaultCellStyling, ...{ alignment: { vertical: 'center', horizontal: 'left' }} } },
                            { v: '', t: 's', s: this.defaultCellStyling },
                            { v: subQuestion.subQuestionType === SubQuestionType.SubQuestion ? this.$t('export.subQuestionAbbr') : this.$t('export.listQuestionAbbr'), t: 's', s: this.defaultCellStyling },
                            { v: '', t: 'n', s: { ...this.defaultCellStyling, ...this.globalNumberFormat } },
                            { v: '', t: 'n', s: { ...this.defaultCellStyling, ...this.globalNumberFormat } },
                            { v: '', t: 's', s: this.defaultCellStyling },
                        ]);
                    });
                });
            });

            // Add a final line containing the sum of alll weights
            questions.push([
                { v: '' },
                { v: '' },
                { v: '' },
                { v: '' },
                { v: '' },
                { v: this.$t('export.sum'), t: 's', s: this.defaultCellStyling },
                { v: totalWeight, t: 'n', s: { ...this.defaultCellStyling, ...this.globalNumberFormat } },
                { v: '' },
                { v: '' },
            ]);

            return questions;
        },
        setColumnSizeToContent(): void {
            setTimeout(() => {
                this.autoSizeAllColumns();
                this.sizeColumnsToFit();
            }, 100);
        },
        autoSizeAllColumns(): void {
            (this.$refs['exportQuestionnaireTableRef'] as any).autoSizeAllColumns();
        },
        sizeColumnsToFit(): void {
            (this.$refs['exportQuestionnaireTableRef'] as any).sizeColumnsToFit();
        }
    }
});

export default QuestionnaireExport;

