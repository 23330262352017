import Vue, { PropType } from 'vue';
import BgTooltip from '@/components/table/custom-tooltips/bgTooltip.vue';
import TableFiltered from '@/components/table-filtered/table-filtered.vue';
import { UserListEndpointRequest } from '@/models';
import { ColumnDef, VendorOptions } from '@/components/table/contracts/table-data';
import { EventBus } from '@/utils';
import moment from 'moment-timezone';
import { UserService } from '@/services/user-service';
import { CatalogTypeDto, LocationDto, SortType, UserDto, UserSortColumn } from '@/service-proxies/service-proxies.g';
import { FilterChangedEvent, GridApi } from 'ag-grid-community';
import EditUserButtonCell from '@/components/user-config-table/custom-cells/edit-user-button-cell.vue';
import CustomMultiSelectFilter from '@/components/table-filtered/custom-filters/multi-select-filter.vue';
import { FakeLocationService } from '@/components/user-config-table/fake-services/fake-location-service';
import { FakeCatalogTypeService } from '@/components/user-config-table/fake-services/fake-catalog-type-service';
import { removeEmptyFieldsFrom } from '@/utils/filter-utils';
import _ from 'lodash';
import { UserRoles } from '@/enums/user-roles';
import { TableData } from '@/components/user-config-table/contracts/table-data';
import UserRoleCell from '@/components/user-config-table/custom-cells/user-role-cell.vue';
import { CustomFilterProps } from '@/components/table-filtered/custom-filters/contracts/CustomFilterProps';
import { ColumnSort } from '@/components/table/contracts/table-enums';

const UserConfigTable = Vue.extend({
    name: 'UserConfigTable',
    props: {
        tableTitle: {
            type: String,
            required: true,
        },
        filterValues: {
            type: Object as PropType<UserListEndpointRequest>,
            required: true
        },
        catalogTypes: {
            type: Array as PropType<Array<CatalogTypeDto>>,
            required: true,
        },
        locations: {
            type: Array as PropType<Array<LocationDto>>,
            required: true,
        }
    },
    components: {
        BgTooltip,
        TableFiltered,
        CustomMultiSelectFilter,
        EditUserButtonCell,
        UserRoleCell
    },
    data (): {
        eventTableName: string;
        lastUpdateRequest: moment.Moment;
        tz: string;
        pagination: {
            totalPages: number;
            rangeOfPages: number;
        };
        showPUIPagination: boolean;
        vendorOptions: VendorOptions<TableData>;
        userService: UserService;
        sortColumnToRequestFieldMap: Record<string, UserSortColumn>;
        filterColumnToRequestFieldMap: Record<string, keyof UserListEndpointRequest>;
        sortTypeToRequestFieldMap: { [key: string]: SortType | undefined };
        gridApi: undefined | GridApi;
        availableColumnsFilterValues: Array<keyof UserListEndpointRequest>;
        fakeLocationService: any;
        fakeCatalogTypeService: any;
        } {
        return {
            eventTableName: 'AdminViewUserConfigTable',
            lastUpdateRequest: moment.tz(moment.tz.guess()),
            tz: moment.tz.guess(),
            pagination: {
                totalPages: 0,
                rangeOfPages: 3,
            },
            showPUIPagination: true,
            vendorOptions: {
                data:
                    _.range(500).map(() => ({
                        'meta': {
                            userId: undefined,
                            userDto: new UserDto(),
                        },
                        'col-1': undefined,
                        'col-1-id': undefined,
                        'col-2': undefined,
                        'col-3': undefined,
                        'col-3-id': undefined,
                        'col-4': undefined,
                        'col-4-id': undefined,
                        'col-details': undefined,
                        'user-role': UserRoles.DummyUnset,
                    })),
                rowSelection: 'single',
                columnDefs: [],
                withPagination: true,
                paginationAutoPageSize: true
            },
            userService: new UserService(),
            sortColumnToRequestFieldMap: {
                'col-1': UserSortColumn.Kid,
                'col-2': UserSortColumn.Name,
            },
            filterColumnToRequestFieldMap: {
                'col-1': 'UserIds',
                'col-3': 'CatalogTypes',
                'col-4': 'LocationIds',
            },
            sortTypeToRequestFieldMap: {
                'asc': SortType.Ascending,
                'desc': SortType.Descending,
            },
            gridApi: undefined,
            availableColumnsFilterValues: ['UserIds', 'CatalogTypes', 'LocationIds'],
            fakeLocationService: new FakeLocationService(this.locations),
            fakeCatalogTypeService: new FakeCatalogTypeService(this.catalogTypes),
        }
    },
    created (): void {
        this.registerEventCallbacks();
        this.vendorOptions.columnDefs = this.defaultColumnDefs();
    },
    async mounted (): Promise<void> {
        await this.constructTable();
    },
    computed: {
        filterColumnToRequestFieldMapInverse (): Record<string, string> {
            const inverseMap: Record<string, string> = {};
            for (const [k, v] of Object.entries(this.filterColumnToRequestFieldMap)) {
                inverseMap[v] = k;
            }
            return inverseMap;
        },
        agGridFilterModel (): Record<string, { value: any }> {
            // Map to correct filter props
            const filterModel: Record<string, { value: any }> = {};
            for (const [k, v] of Object.entries(this.filterValues)) {
                const column = this.filterColumnToRequestFieldMapInverse[k];
                if (this.availableColumnsFilterValues.includes(k as keyof UserListEndpointRequest)) {
                    filterModel[column] = {
                        value: v,
                    };
                }
            }

            return filterModel;
        }
    },
    methods: {
        /* eslint-disable sonarjs/no-duplicate-string */
        defaultColumnDefs (): ColumnDef[] {
            const userCustomUserFilterParams: CustomFilterProps<UserDto> = {
                filterLabel: this.$t('filterLabels.kid').toString(),
                dataName: 'col-1-id',
                apiService: this.userService,
                valueName: 'kid',
                labelName: 'kid',
                secondaryLabelName: 'name'
            }
            const catalogTypesCustomUserFilterParams: CustomFilterProps<CatalogTypeDto> = {
                filterLabel: this.$t('filterLabels.catalogType').toString(),
                dataName: 'col-3-id',
                apiService: this.fakeCatalogTypeService,
                valueName: 'catalogTypeDescriptions',
                labelName: 'catalogTypeDescriptions',
                secondaryLabelName: 'catalogTypeAbbr'
            }
            const locationCustomUserFilterParams: CustomFilterProps<LocationDto> = {
                filterLabel: this.$t('filterLabels.location').toString(),
                dataName: 'col-4-id',
                apiService: this.fakeLocationService,
                valueName: 'locationName',
                labelName: 'locationName',
                secondaryLabelName: 'locationAbbr'
            }
            return [
                {
                    headerName: this.$t('userConfigTable.kid').toString(),
                    field: 'col-1', tooltipComponent: 'BgTooltip',
                    tooltipField: 'col-1', tooltipComponentParams: { key: 'col-1' },
                    comparator: (): number => 0,
                    filter: 'CustomMultiSelectFilter',
                    filterParams: userCustomUserFilterParams,
                    sort: ColumnSort.asc
                },
                {
                    headerName: this.$t('userConfigTable.name').toString(),
                    field: 'col-2',
                    tooltipComponent: 'BgTooltip',
                    tooltipField: 'col-2',
                    tooltipComponentParams: { key: 'col-2' },
                    comparator: (): number => 0,
                    filter: false
                },
                {
                    headerName: this.$t('userConfigTable.role').toString(),
                    field: 'user-role',
                    minWidth: 50,
                    cellRenderer: 'UserRoleCell',
                    filter: false,
                    sortable: false,
                },
                {
                    headerName: this.$t('userConfigTable.catalogTypePermissions').toString(),
                    field: 'col-3',
                    tooltipComponent: 'BgTooltip',
                    tooltipField: 'col-3',
                    tooltipComponentParams: { key: 'col-3' },
                    comparator: (): number => 0,
                    filter: 'CustomMultiSelectFilter',
                    filterParams: catalogTypesCustomUserFilterParams,
                    sortable: false,
                },
                {
                    headerName: this.$t('userConfigTable.locationPermissions').toString(),
                    field: 'col-4',
                    tooltipComponent: 'BgTooltip',
                    tooltipField: 'col-4',
                    tooltipComponentParams: { key: 'col-4' },
                    comparator: (): number => 0,
                    filter: 'CustomMultiSelectFilter',
                    filterParams: locationCustomUserFilterParams,
                    sortable: false,
                },
                {
                    headerName: this.$t(`userConfigTable.details`).toString(),
                    field: `col-details`,
                    minWidth: 50,
                    cellRenderer: 'EditUserButtonCell',
                    filter: false,
                    sortable: false,
                }
            ];
        },
        registerEventCallbacks (): void {
            EventBus.$on(EventBus.VIEWS.ADMIN.REFRESH_TABLE, () => {
                this.constructTable();
            });
        },
        async constructTable (): Promise<void> {
            const { filteredEvents, time } = await this.getFilteredValues(this.filterValues);

            // only update the table if this is really the latest request
            if (time.valueOf() === this.lastUpdateRequest.valueOf()) {
                this._constructTable(filteredEvents);
            }
        },
        async getFilteredValues (filters: UserListEndpointRequest): Promise<{ filteredEvents: UserDto[]; time: moment.Moment }> {
            // make sure the datetimes are in utc!
            // TODO: Not needed at the moment
            const utcFilters: UserListEndpointRequest = {
                ...filters,
            }

            const time = moment.tz(this.tz);
            this.lastUpdateRequest = time; // save the current time to handle overlapping requests
            const res = (await this.userService.getUsers(utcFilters)).result;

            // success
            if (res) {
                this.pagination.totalPages = res.totalPages === 0 ? 1 : res.totalPages ?? 0;
                this.showPUIPagination = false;
                return {
                    filteredEvents: res.items ?? [],
                    time: time
                };
            }

            // failure
            return {
                filteredEvents: [],
                time: time,
            }
        },
        _constructTable (filteredEvents: UserDto[]): void {
            function toUserRole(role: string): UserRoles {
                return _.startCase(_.camelCase(role)).replace(/ /g, '') as UserRoles;
            }
            const useCaseId = this.$store.getters['appData/useCaseId'] as number;
            this.vendorOptions.data = [];
            filteredEvents.forEach((user: UserDto) => {
                this.vendorOptions.data.push(
                    {
                        'meta': {
                            userId: user.id,
                            userDto: user,
                        },
                        'col-1': user.kid,
                        'col-1-id': user.id,
                        'col-2': user.name,
                        'col-3': user.catalogTypePermissionDtos?.map(x => this.catalogTypes.find(y => y.id === x.catalogTypeId)?.getDescription(this.$i18n.locale)),
                        'col-3-id': user.catalogTypePermissionDtos?.map(x => this.catalogTypes.find(y => y.id === x.catalogTypeId)?.id),
                        'col-4': user.locationPermissionDtos?.map(x => this.locations.find(y => y.id === x.locationId)?.locationName),
                        'col-4-id': user.locationPermissionDtos?.map(x => this.locations.find(y => y.id === x.locationId)?.id),
                        'col-details': this.$t('userConfigTable.details').toString(),
                        'user-role': toUserRole(user.userGroupTypes?.filter(x => x.useCaseId === useCaseId).map(x => x.name)[0] as string),
                    }
                );

                // Edit data if user is an admin. In this case he has access to everything!
                const lastElement = this.vendorOptions.data[this.vendorOptions.data.length - 1];
                if (lastElement['user-role'] === UserRoles.Admin) {
                    lastElement['col-3'] = this.catalogTypes.map(x => x.getDescription(this.$i18n.locale));
                    lastElement['col-3-id'] = this.catalogTypes.map(x => x.id);
                    lastElement['col-4'] = this.locations.map(x => x.locationName);
                    lastElement['col-4-id'] = this.locations.map(x => x.id);
                }
            });
        },
        onSortChanged (sortChangedEvent: any): void {

            // caution: this doesnt work as expected once, multi-column sort is activated
            // only the first found sorted column will be used
            const currentSortedColumn = sortChangedEvent.columnApi
                .getColumnState()
                .find((col: any) => col.sort === 'asc' || col.sort === 'desc');

            if (!currentSortedColumn) {
                this.filterValues.SortColumn = undefined;
                this.filterValues.SortDirection = undefined;
                return;
            }

            if (this.sortColumnToRequestFieldMap[currentSortedColumn.colId] == null
                || this.sortColumnToRequestFieldMap[currentSortedColumn.colId] == undefined) {
                return;
            }

            this.filterValues.SortDirection = this.sortTypeToRequestFieldMap[currentSortedColumn.sort];
            this.filterValues.SortColumn = this.sortColumnToRequestFieldMap[currentSortedColumn.colId];
        },
        onAGGridFilterChanged (filterChangedEvent: FilterChangedEvent): void {
            const filters = filterChangedEvent.api.getFilterModel();
            this.gridApi = filterChangedEvent.api;

            // Map to correct filter props
            // As a first step set everything to undefined
            const mappedFilters: Record<string, string | undefined> = {};
            for (const el of this.availableColumnsFilterValues) {
                mappedFilters[el] = undefined;
            }

            // then fill with actual filter values
            for (const [k, v] of Object.entries(filters)) {
                const mappedValue = this.filterColumnToRequestFieldMap[k];
                if (mappedValue) {
                    // standard ag-grid uses 'filter' but our custom filter uses 'value'
                    if (v.filter) {
                        mappedFilters[mappedValue] = v.filter;
                    } else {
                        mappedFilters[mappedValue] = v.value;
                    }
                }
            }

            const newFilterValues = { ...this.filterValues };
            // Add filters to "real/global" filter object which is passed down again to the ag grid table
            for (const [k, v] of Object.entries(mappedFilters)) {
                Vue.set(newFilterValues, k as keyof UserListEndpointRequest, v as never);
            }

            // Update global filter
            this.$emit('userFilterChanged', removeEmptyFieldsFrom<UserListEndpointRequest>(newFilterValues));
        },
    }
});

export default UserConfigTable;
