import Vue, { PropType } from 'vue';
import BgTooltip from '@/components/table/custom-tooltips/bgTooltip.vue';
import TableFiltered from '@/components/table-filtered/table-filtered.vue';
import { ReportListEndpointRequest } from '@/models';
import { ColumnDef, VendorOptions } from '@/components/table/contracts/table-data';
import { EventBus } from '@/utils';
import moment from 'moment-timezone';
import {
    LocationDto,
    LocationPermissionDto,
    QuestionCatalogDto,
    QuestionCatalogSortColumn,
    ReportApprovalStatus,
    ReportDto,
    ReportSortColumn, ReportType,
    SortType,
} from '@/service-proxies/service-proxies.g';
import { FilterChangedEvent, GridApi } from 'ag-grid-community';
import CustomMultiSelectFilter from '@/components/table-filtered/custom-filters/multi-select-filter.vue';
import { removeEmptyFieldsFrom } from '@/utils/filter-utils';
import { ReportService } from '@/services/report-service';
import { LocalStorageHelper } from '@/utils/local-storage-helper';
import { LANGUAGES } from '@/utils/languages';
import EditReportButtonCell from '@/components/report-table/custom-cells/edit-report-button-cell.vue';
import { UserActions } from '@/enums/user-actions';
import {
    ApprovalStatus,
    FakeApprovalStatusService
} from '@/components/report-table/fake-services/fake-approval-status-service';
import ApprovalStatusCell from '@/components/report-table/custom-cells/approval-status-cell.vue';
import { CustomFilterProps } from '@/components/table-filtered/custom-filters/contracts/CustomFilterProps';
import { ColumnSort } from '@/components/table/contracts/table-enums';
import { QuestionCatalogService } from '@/services/question-catalog-service';
import { apiCallWithErrorHandling } from '@/services/utils';
import _ from 'lodash';
import { ReportTableData } from './contracts/table-data';

const ReportTable = Vue.extend({
    name: 'ReportTable',
    props: {
        tableTitle: {
            type: String,
            required: true,
        },
        filterValues: {
            type: Object as PropType<ReportListEndpointRequest>,
            required: true
        },
        hideAddButton: {
            type: Boolean,
            default: false,
        },
        hideDetails: {
            type: Boolean,
            default: false,
        },
        filtersAvailable: {
            type: Boolean,
            default: true,
        },
        testReportMode: {
            type: Boolean,
            default: false,
        },
        skipInitialLoad: {
            type: Boolean,
            default: false,
        }
    },
    components: {
        BgTooltip,
        TableFiltered,
        CustomMultiSelectFilter,
        EditReportButtonCell,
        ApprovalStatusCell
    },
    data (): {
        eventTableName: string;
        lastUpdateRequest: moment.Moment;
        tz: string;
        pagination: {
            totalPages: number;
            rangeOfPages: number;
        };
        vendorOptions: VendorOptions<ReportTableData>;
        reportService: ReportService;
        questionCatalogService: QuestionCatalogService;
        sortColumnToRequestFieldMap: Record<string, ReportSortColumn>;
        filterColumnToRequestFieldMap: Record<string, keyof ReportListEndpointRequest>;
        sortTypeToRequestFieldMap: { [key: string]: SortType | undefined };
        gridApi: undefined | GridApi;
        filterChangedEvent: any;
        availableColumnsFilterValues: Array<keyof ReportListEndpointRequest>;
        selectedLanguage: string;
        fakeApprovalStatusService: FakeApprovalStatusService;
        } {
        return {
            eventTableName: 'UserViewReportTable',
            lastUpdateRequest: moment.tz(moment.tz.guess()),
            tz: moment.tz.guess(),
            pagination: {
                totalPages: 1,
                rangeOfPages: 3,
            },
            vendorOptions: {
                data: _.range(500).map(() => ({
                    'meta': {
                        reportDto: new ReportDto(),
                    },
                    'col-description': '',
                    'col-catalogTitle': '',
                    'col-year': undefined,
                    'col-type': '',
                    'col-version': undefined,
                    'col-details': '',
                    'col-reportType': '',
                    'col-location': '',
                    'col-approval-state': undefined,
                })),
                rowSelection: 'single',
                columnDefs: [],
                withPagination: true,
                paginationAutoPageSize: true,
                suppressPaginationPanel: this.testReportMode ? true : 'false'
            },
            reportService: new ReportService(),
            questionCatalogService: new QuestionCatalogService(),
            sortColumnToRequestFieldMap: {
                'col-description': ReportSortColumn.Description,
                'col-year': ReportSortColumn.Year,
                'col-version': ReportSortColumn.Version,
            },
            filterColumnToRequestFieldMap: {
                'col-questionCatalogs': 'questionCatalogIds',
                'col-approval-state': 'approvalStatus',
                'col-reportType': 'reportTypeIds'
            },
            sortTypeToRequestFieldMap: {
                'asc': SortType.Ascending,
                'desc': SortType.Descending,
            },
            gridApi: undefined,
            filterChangedEvent: undefined,
            availableColumnsFilterValues: ['questionCatalogIds', 'approvalStatus', 'reportTypeIds'],
            selectedLanguage: 'en-GB',
            fakeApprovalStatusService: new FakeApprovalStatusService(),
        }
    },
    created (): void {
        this.registerEventCallbacks();
        this.vendorOptions.columnDefs = this.defaultColumnDefs();
    },
    async mounted (): Promise<void> {
        this.selectedLanguage = LANGUAGES[LocalStorageHelper.getSelectedLanguage() ?? 'EN'];
    },
    computed: {
        filterColumnToRequestFieldMapInverse (): Record<string, string> {
            const inverseMap: Record<string, string> = {};
            for (const [k, v] of Object.entries(this.filterColumnToRequestFieldMap)) {
                inverseMap[v] = k;
            }
            return inverseMap;
        },
        agGridFilterModel (): Record<string, { value: any }> {
            // Map to correct filter props
            const filterModel: Record<string, { value: any }> = {};
            for (const [k, v] of Object.entries(this.filterValues)) {
                const column = this.filterColumnToRequestFieldMapInverse[k];
                if (this.availableColumnsFilterValues.includes(k as keyof ReportListEndpointRequest)) {
                    filterModel[column] = {
                        value: v,
                    };
                }
            }

            return filterModel;
        },
        canCreateReport (): boolean {
            const locations = this.$store.getters['userAccessManagement/getUserLocations'] as LocationPermissionDto[];
            return locations.length > 0 &&
            this.$store.getters['userAccessManagement/getUserPermissions'].includes(UserActions.CreateReport);
        },
        locations (): LocationDto[] {
            return this.$store.getters['locations/locations'];
        }
    },
    methods: {
        addButtonClicked (): void {
            EventBus.$emit(EventBus.VIEWS.USER.REPORT_ADD_BUTTON_CLICKED);
        },
        /* eslint-disable sonarjs/no-duplicate-string */
        defaultColumnDefs (): ColumnDef[] {
            const approvalStatusCustomUserFilterParams: CustomFilterProps<ApprovalStatus> = {
                filterLabel: this.$t('filterLabels.approvalStatus').toString(),
                dataName: 'col-approval-state',
                apiService: this.fakeApprovalStatusService,
                valueName: 'id',
                labelName: 'description',
                staticList: this.fakeApprovalStatusService.getTranslatedItems().map(i => ({ id: i.id, description: this.$t(i.description).toString()})),
            }

            const approvalReportTypeCustomUserFilterParams: any = {
                filterLabel: 'Report Type',
                dataName: 'col-reportType',
                // apiService: this.fakeApprovalStatusService,
                valueName: 'id',
                labelName: 'description',
                staticList: [{id: 1, description: 'InterimReport'}, {id: 0, description: 'MainReport'}, {id: 2, description: 'TestReport'}]
            }

            const detailsColumn: ColumnDef = {
                headerName: this.$t(`reportTable.details`).toString(),
                field: `col-details`,
                minWidth: 50,
                cellRenderer: 'EditReportButtonCell',
                filter: false,
                sortable: false,
            };

            const columnRecord: Record<string, ColumnDef[]> = {
                normal: [
                    {
                        headerName: this.$t('reportTable.description').toString(),
                        field: 'col-description', tooltipComponent: 'BgTooltip',
                        tooltipField: 'col-description', tooltipComponentParams: { key: 'col-description' },
                        comparator: (): number => 0,
                        filter: true,
                        sortable: false
                    },
                    {
                        headerName: this.$t('reportTable.year').toString(),
                        field: 'col-year', tooltipComponent: 'BgTooltip',
                        tooltipField: 'col-year', tooltipComponentParams: { key: 'col-year' },
                        comparator: (): number => 0,
                        filter: true,
                        sortable: false
                        // sort: ColumnSort.desc,
                    },
                    {
                        headerName: this.$t('reportTable.type').toString(),
                        field: 'col-type', tooltipComponent: 'BgTooltip',
                        tooltipField: 'col-type', tooltipComponentParams: { key: 'col-type' },
                        comparator: (): number => 0,
                        filter: true,
                        sortable: false,
                    },
                    {
                        headerName: this.$t('reportTable.reportType').toString(),
                        field: 'col-reportType', tooltipComponent: 'BgTooltip',
                        tooltipField: 'col-reportType', tooltipComponentParams: { key: 'col-reportType' },
                        comparator: (): number => 0,
                        filter: 'CustomMultiSelectFilter',
                        filterParams: approvalReportTypeCustomUserFilterParams,
                        sortable: false,
                        // cellRenderer: 'ApprovalStatusCell',
                    },
                    {
                        headerName: this.$t('reportTable.version').toString(),
                        field: 'col-version', tooltipComponent: 'BgTooltip',
                        tooltipField: 'col-version', tooltipComponentParams: { key: 'col-version' },
                        comparator: (): number => 0,
                        filter: false,
                    },
                    {
                        headerName: this.$t('reportTable.location').toString(),
                        field: 'col-location', tooltipComponent: 'BgTooltip',
                        tooltipField: 'col-location', tooltipComponentParams: { key: 'col-location' },
                        comparator: (): number => 0,
                        filter: true,
                        sortable: false,
                    },
                    {
                        headerName: this.$t('reportTable.approvalState').toString(),
                        field: 'col-approval-state', tooltipComponent: 'BgTooltip',
                        tooltipField: 'col-approval-state', tooltipComponentParams: { key: 'col-approval-state' },
                        comparator: (): number => 0,
                        filter: 'CustomMultiSelectFilter',
                        filterParams: approvalStatusCustomUserFilterParams,
                        sortable: false,
                        cellRenderer: 'ApprovalStatusCell',
                    },
                ],
                test: [
                    {
                        headerName: this.$t('reportTable.description').toString(),
                        field: 'col-description', tooltipComponent: 'BgTooltip',
                        tooltipField: 'col-description', tooltipComponentParams: { key: 'col-description' },
                        comparator: (): number => 0,
                        filter: false,
                    },
                    {
                        headerName: this.$t('reportTable.catalogTitle').toString(),
                        field: 'col-catalogTitle', tooltipComponent: 'BgTooltip',
                        tooltipField: 'col-catalogTitle', tooltipComponentParams: { key: 'col-catalogTitle' },
                        comparator: (): number => 0,
                        filter: false,
                        sortable: false,
                    },
                    {
                        headerName: this.$t('reportTable.year').toString(),
                        field: 'col-year', tooltipComponent: 'BgTooltip',
                        tooltipField: 'col-year', tooltipComponentParams: { key: 'col-year' },
                        comparator: (): number => 0,
                        filter: false,
                        sort: ColumnSort.desc,
                    },
                    {
                        headerName: this.$t('reportTable.type').toString(),
                        field: 'col-type', tooltipComponent: 'BgTooltip',
                        tooltipField: 'col-type', tooltipComponentParams: { key: 'col-type' },
                        comparator: (): number => 0,
                        filter: false,
                        sortable: false,
                    },
                    {
                        headerName: this.$t('reportTable.version').toString(),
                        field: 'col-version', tooltipComponent: 'BgTooltip',
                        tooltipField: 'col-version', tooltipComponentParams: { key: 'col-version' },
                        comparator: (): number => 0,
                        filter: false,
                    }
                ],
            }

            const columns = this.testReportMode
                ? columnRecord.test
                : columnRecord.normal;

            if (!this.hideDetails) {
                columns.push(detailsColumn)
            }

            return columns;
        },
        registerEventCallbacks (): void {
            EventBus.$on(EventBus.VIEWS.USER.REFRESH_TABLE, () => {
                this.constructTable();
            });
        },
        async constructTable (): Promise<void> {

            const { filteredEvents, time } = await this.getFilteredValues(this.filterValues);

            // only update the table if this is really the latest request
            if (time.valueOf() === this.lastUpdateRequest.valueOf()) {
                await this._constructTable(filteredEvents);
            }
        },
        async getFilteredValues (filters: ReportListEndpointRequest): Promise<{ filteredEvents: ReportDto[]; time: moment.Moment }> {
            // make sure the datetimes are in utc!
            // TODO: Not needed at the moment
            const utcFilters: ReportListEndpointRequest = {
                ...filters,
            }

            const time = moment.tz(this.tz);
            this.lastUpdateRequest = time; // save the current time to handle overlapping requests
            // if(!utcFilters.reportTypeIds){
            //     Vue.set(this.filterValues, 'reportTypeIds', [ReportType.MainReport, ReportType.InterimReport]);
            // }
            const res = (await this.reportService.getReports(utcFilters)).result;

            // success
            if (res) {
                this.pagination.totalPages = res.totalPages === 0 ? 1 : res.totalPages ?? 0;
                return {
                    filteredEvents: res.items ?? [],
                    time: time
                };
            }

            // failure
            return {
                filteredEvents: [],
                time: time,
            }
        },
        onPageSizeFound(pageSize: number): void {
            if(this.testReportMode) {
                this.filterValues.size = pageSize;
            } else {
                this.filterValues.size = 10000;
            }
        },
        async getQuestionCatalogs(ids: number[]): Promise<QuestionCatalogDto[]> {
            if (ids.length === 0) {
                return [];
            }

            const result = await apiCallWithErrorHandling(
                this.questionCatalogService.getQuestionCatalogs,
                {
                    sortDirection: SortType.Ascending,
                    sortProperty: QuestionCatalogSortColumn.Description,
                    questionCatalogIds: _.uniq(ids),
                    catalogTypeIds: undefined,
                    reportYear: undefined,
                    catalogVersion: undefined,
                    description: undefined,
                    questionCatalogStatus: undefined,
                    page: 1,
                    size: ids.length,
                    Term: undefined,
                    TermToLower: undefined,
                },
                (this as any).$pui.toast,
                {
                    errorTitle: this.$t('reportTable.catalogLoadingToast.errorTitle').toString(),
                    errorText: this.$t('reportTable.catalogLoadingToast.errorText').toString(),
                },
                this.$store
            );
            return result?.result?.items ?? [];
        },
        async _constructTable (filteredEvents: ReportDto[]): Promise<void> {
            this.vendorOptions.data = [];
            if (this.testReportMode) {
                const catalogIds = filteredEvents.map(x => x.questionCatalogId).filter(x => x) as number[];
                const catalogs = await this.getQuestionCatalogs(catalogIds);
                filteredEvents.forEach((report: ReportDto) => {
                    this.vendorOptions.data.push(
                        {
                            'meta': {
                                reportDto: report,
                            },
                            'col-description': report.description,
                            'col-catalogTitle': catalogs.filter(x => x.id === report.questionCatalogId)[0].description,
                            'col-year': report.reportYear,
                            'col-type': report.catalogType?.getDescription(this.$i18n.locale),
                            'col-version': report.version,
                            'col-details': this.$t('reportTable.details').toString(),
                        }
                    );
                });
            } else {
                filteredEvents.forEach((report: ReportDto) => {
                    this.vendorOptions.data.push(
                        {
                            'meta': {
                                reportDto: report,
                            },
                            'col-description': report.description,
                            'col-year': report.reportYear,
                            'col-type': report.catalogType?.getDescription(this.$i18n.locale),
                            'col-reportType': this.$t(`reportType.${ReportType[report.reportType as unknown as number]}`).toString(),
                            'col-version': report.version,
                            'col-location': this.locations.filter(x => x.id === report.locationId)[0].locationName ?? '',
                            'col-approval-state':  ReportApprovalStatus[report.approvalStatus ?? 0],
                            'col-details': this.$t('reportTable.details').toString(),
                        }
                    );
                });
            }
        },
        onSortChanged (sortChangedEvent: any): void {

            // caution: this doesnt work as expected once, multi-column sort is activated
            // only the first found sorted column will be used
            const currentSortedColumn = sortChangedEvent.columnApi
                .getColumnState()
                .find((col: any) => col.sort === 'asc' || col.sort === 'desc');

            if (!currentSortedColumn) {
                this.filterValues.sortProperty = undefined;
                this.filterValues.sortDirection = undefined;
                return;
            }

            if (this.sortColumnToRequestFieldMap[currentSortedColumn.colId] == null
                || this.sortColumnToRequestFieldMap[currentSortedColumn.colId] == undefined) {
                return;
            }

            this.filterValues.sortDirection = this.sortTypeToRequestFieldMap[currentSortedColumn.sort];
            this.filterValues.sortProperty = this.sortColumnToRequestFieldMap[currentSortedColumn.colId];
        },
        onAGGridFilterChanged (filterChangedEvent: FilterChangedEvent): void {
            this.filterChangedEvent = filterChangedEvent;
            const filters = filterChangedEvent.api.getFilterModel();
            this.gridApi = filterChangedEvent.api;

            // Map to correct filter props
            // As a first step set everything to undefined
            const mappedFilters: Record<string, string | undefined> = {};
            for (const el of this.availableColumnsFilterValues) {
                mappedFilters[el] = undefined;
            }

            // then fill with actual filter values
            for (const [k, v] of Object.entries(filters)) {
                const mappedValue = this.filterColumnToRequestFieldMap[k];
                if (mappedValue) {
                    // standard ag-grid uses 'filter' but our custom filter uses 'value'
                    if (v.filter) {
                        mappedFilters[mappedValue] = v.filter;
                    } else {
                        mappedFilters[mappedValue] = v.value;
                    }
                }
            }

            const newFilterValues = { ...this.filterValues };
            // Add filters to "real/global" filter object which is passed down again to the ag grid table
            for (const [k, v] of Object.entries(mappedFilters)) {
                Vue.set(newFilterValues, k as keyof ReportListEndpointRequest, v as never);
            }

            // Update global filter
            this.$emit('reportFilterChanged', removeEmptyFieldsFrom<ReportListEndpointRequest>(newFilterValues));
        },
        onPaginationChange (newPage: any): void {
            // if(this.filterChangedEvent){
            //     const filters = this.filterChangedEvent.api.getFilterModel();
            //     this.gridApi = this.filterChangedEvent.api;
    
            //     // Map to correct filter props
            //     // As a first step set everything to undefined
            //     const mappedFilters: Record<string, string | undefined> = {};
            //     for (const el of this.availableColumnsFilterValues) {
            //         mappedFilters[el] = undefined;
            //     }
    
            //     // then fill with actual filter values
            //     for (const [k, v] of Object.entries(filters) as any) {
            //         const mappedValue = this.filterColumnToRequestFieldMap[k];
            //         if (mappedValue) {
            //             // standard ag-grid uses 'filter' but our custom filter uses 'value'
            //             if (v.filter) {
            //                 mappedFilters[mappedValue] = v.filter;
            //             } else {
            //                 mappedFilters[mappedValue] = v.value;
            //             }
            //         }
            //     }
    
            //     const newFilterValues = { ...this.filterValues };
            //     // Add filters to "real/global" filter object which is passed down again to the ag grid table
            //     for (const [k, v] of Object.entries(mappedFilters)) {
            //         Vue.set(newFilterValues, k as keyof ReportListEndpointRequest, v as never);
            //     }
    
            //     // Update global filter
            //     this.$emit('reportFilterChanged', removeEmptyFieldsFrom<ReportListEndpointRequest>(newFilterValues));
            // }
        },
    }
});

export default ReportTable;
