
import Vue from 'vue';
import { IDoesFilterPassParams, IFilterParams } from 'ag-grid-community';
import { MultiSelectOption } from '@/components/table-filtered/custom-filters/contracts/MultiSelectOption';
import { CustomFilterProps } from '@/components/table-filtered/custom-filters/contracts/CustomFilterProps';
import { CatalogTypeDto, ReportApprovalStatus } from '@/service-proxies/service-proxies.g';

const CustomMultiSelectFilter = Vue.extend({
    name: 'CustomMultiSelectFilter',
    data (): {
        values: (string | number)[];
        suggestionsIsLoading: boolean;
        placeholder: string;
        suggestions: Array<MultiSelectOption>;
        internalSelection: any;
        } {
        return {
            values: [],
            suggestionsIsLoading: false,
            placeholder: '',
            suggestions: [],
            internalSelection: [],
        };
    },
    computed: {
        agGridParams (): IFilterParams & CustomFilterProps<{}> {
            return (this as any).params;
        },
        isStaticMode (): boolean {
            return this.agGridParams.staticList !== undefined;
        }
    },
    mounted (): void {
        this.suggestions = this.itemsToOptions(this.agGridParams.staticList);
        this.placeholder = this.agGridParams.placeholder || '';
    },
    methods: {
        // eslint-disable-next-line sonarjs/cognitive-complexity
        itemsToOptions (items: any[] | undefined): MultiSelectOption[] {
            // Special case: value is an array! Currently, this only happens for questionCatalogType
            if (items && items[0] && Array.isArray(items[0][this.agGridParams.labelName]) && items[0] instanceof CatalogTypeDto) {
                return items?.map(
                    (x: any) => {
                        return {
                            label: this.agGridParams.labelName ? x.getDescription(this.$i18n.locale) : '',
                            secondaryLabel: this.agGridParams.secondaryLabelName ? x.getDescription(this.$i18n.locale) : '',
                            value: this.agGridParams.valueName ? x.getDescription(this.$i18n.locale) : '',
                            id: x.id }
                    }
                ) ?? [];
            }

            return items?.map(
                (x: any) => {
                    return {
                        label: this.agGridParams.labelName ? x[this.agGridParams.labelName] : '',
                        secondaryLabel: this.agGridParams.secondaryLabelName ? x[this.agGridParams.secondaryLabelName ?? 0] : '',
                        value: this.agGridParams.valueName ? x[this.agGridParams.valueName] : '',
                        disabled: (x.id == ReportApprovalStatus.Finalized),
                        id: x.id }
                }
            ) ?? [];
        },
        updateFilter (): void {
            this.agGridParams.filterChangedCallback();
        },
        doesFilterPass (params: IDoesFilterPassParams): boolean {
            // Because we filter in the backend we don't need an additional check here
            // This check can actually become a bit difficult when we deal with enums (value != key)
            return true;
        },
        isFilterActive (): boolean {
            return this.values.length !== 0;
        },
        getModel (): null | { value: any[] } {
            if (!this.isFilterActive()) {
                return null;
            }
            return { value: this.values };
        },
        setModel (model: { value: any[] } | null | undefined): void {
            this.values = !(model && model.value)
                ? [] as string[]
                : model.value.map(x => x);
        },
        async onSuggestionSearch (payLoad: string): Promise<void> {
            this.suggestionsIsLoading = true;
            if (this.agGridParams.apiService) {
                const items = (await this.agGridParams.apiService?.getItems(
                    {
                        Term: payLoad,
                        page: 1,
                        size: 6,
                    })).result.items as any[];
                this.suggestions = this.itemsToOptions(items);
            }

            this.suggestionsIsLoading = false;
        },
        onSelectionChange (changeEvent: Array<MultiSelectOption>): void {
            if (changeEvent) {
                this.$set(this, 'values', changeEvent.map(x => x.id));
            }
            else {
                this.$set(this, 'values', []);
            }
            this.updateFilter();
        },
    }
});
export default CustomMultiSelectFilter;
