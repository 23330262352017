
import Vue, { PropType } from 'vue';
import { CatalogTypeDto, LocationCatalogTypeDto, LocationDto } from '@/service-proxies/service-proxies.g';
import _ from 'lodash';
import { LocationService } from '@/services/location-service';
import { apiCallWithErrorHandling } from '@/services/utils';
import { EventBus } from '@/utils';
import { WAITING_MODAL_DEFAULT_TEXT, WAITING_MODAL_DEFAULT_TITLE } from '@/store/modules/waiting-modal';
import '@/utils/extensions'

const LocationDetails = Vue.extend({
    name: 'LocationDetails',
    props: {
        locationDto: {
            type: Object as PropType<LocationDto>,
            required: true,
        }
    },
    data (): {
        internalLocation: LocationDto;
        cardColor: string;
        dataLoaded: boolean;
        locationApi: LocationService;
        } {
        return {
            internalLocation: _.cloneDeep(this.locationDto),
            cardColor: '#0078dc',
            dataLoaded: false,
            locationApi: new LocationService(),
        }
    },
    mounted (): void {
        this.dataLoaded = true;
    },
    watch: {
        locationDto: {
            handler (newVal: LocationDto): void {
                this.internalLocation = _.cloneDeep(newVal);
            },
            deep: true,
        },
        dataModified: {
            handler(newValue: boolean): void {
                this.$emit('data-modified', newValue);
            }
        }
    },
    computed: {
        catalogTypes (): CatalogTypeDto[] {
            return this.$store.getters['catalogTypes/catalogTypes'];
        },
        cardTitle (): string {
            return this.internalLocation.locationName ?? '';
        },
        checkedDictionary (): Record<string, boolean> {
            return Object.fromEntries(
                this.catalogTypes.map(x => [
                    x.id,
                    Boolean(this.internalLocation.locationCatalogTypeDtos?.find(y => y.catalogTypeId === x.id))]
                )
            );
        },
        dataModified (): boolean {
            const currentData = this.locationDto.locationCatalogTypeDtos;
            const newData = this.internalLocation.locationCatalogTypeDtos;

            return !_.isEqual(
                newData?.map(x => ({ locationId: x.locationId, catalogTypeId: x.catalogTypeId })),
                currentData?.map(x => ({ locationId: x.locationId, catalogTypeId: x.catalogTypeId }))
            );
        },
    },
    methods: {
        onChange (id: number, checked: boolean): void {
            const types = this.internalLocation.locationCatalogTypeDtos;

            // No List/undefined? Then we add an empty list here
            if (!types) {
                this.internalLocation.locationCatalogTypeDtos = [];
            }

            // There should only be two cases, the else statement is just for debugging
            const idx = _.findIndex(types, (x: any) => x.catalogTypeId === id)
            if (idx >= 0 && !checked) {
                types?.splice(idx, 1);
            } else if (idx < 0 && checked) {
                types?.push(new LocationCatalogTypeDto({
                    id: 0,
                    locationId: this.internalLocation.id,
                    catalogTypeId: id,
                }));
            } else {
                console.error('An unexpected error occured while changing the location catalog type configuration');
            }
        },
        async submitChanges (): Promise<void> {
            const response = await apiCallWithErrorHandling(
                this.locationApi.editLocation,
                this.internalLocation,
                (this as any).$pui.toast,
                {
                    successTitle: this.$t('locationDetails.locationChangeToast.successTitle').toString(),
                    successText: this.$t('locationDetails.locationChangeToast.successText').toString(),
                    errorTitle: this.$t('locationDetails.locationChangeToast.errorTitle').toString(),
                    errorText: this.$t('locationDetails.locationChangeToast.errorText').toString(),
                    waitingTitle: this.$t(WAITING_MODAL_DEFAULT_TITLE).toString(),
                    waitingText: this.$t(WAITING_MODAL_DEFAULT_TEXT).toString(),
                    forbiddenErrorText: this.$t('locationDetails.locationChangeToast.forbiddenErrorText').toString(),
                },
                this.$store
            );

            // Update Location
            if (response && response?.result) {
                this.internalLocation = response.result;
                EventBus.$emit(EventBus.VIEWS.ADMIN.REFRESH_TABLE);
                EventBus.$emit(EventBus.VIEWS.ADMIN.LOCATION_EDITED, _.cloneDeep(this.internalLocation))

                // In addition, we need to reload the locations which updates the locationCatalogTypes in the vuex store
                await this.$store.commit('locations/SET_LOCATION', _.cloneDeep(this.internalLocation));
            }
        }
    }
});

export default LocationDetails;
